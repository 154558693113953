<template>
  <div class="home">
    <Swiper></Swiper>
    <!-- <SubNav></SubNav>
    <box></box>
    <Sixbox></Sixbox>
    <four-boxa></four-boxa> -->
    <!-- 平台 -->
    <div class="server">
      <img class="serverImg" :src="serverImg" alt=""  ondragstart="return false;" oncontextmenu="return false;">
    </div>
     <div class="effect">
      <img class="effectImg" :src="effectImg" alt="" ondragstart="return false;" oncontextmenu="return false;">
    </div>
     <div class="success">
      <img class="successImg" :src="successImg" alt="" ondragstart="return false;" oncontextmenu="return false;">
    </div>
    <!-- 6 -->
    <div class="message">
      <message></message>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Swiper from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
import Box from "./modules/box.vue";
import Sixbox from "./modules/SixBoxa.vue";
import FourBoxa from "./modules/FourBoxa.vue";
import Message from "@/components/message/Message.vue";

export default defineComponent({
  name: "home",
  components: {
    Swiper,
    SubNav,
    Message,
    Box,
    Sixbox,
    FourBoxa,
  },
  data() {
    return {
      serverImg: require("@/assets/1new/生涯咨询/2.jpg"),
      effectImg: require("@/assets/1new/生涯咨询/3.jpg"),
      successImg: require("@/assets/1new/生涯咨询/4.jpg"),
    };
  },
});
</script>
<style lang="scss" scoped>
// .news {
//   width: 1200px;
//   // min-height: 200px;
//   background-color:skyblue;
//   height: 100%;
//   margin-bottom: 20px;
//   margin-top: 20px;
//   position: relative;
//   display: block;
// }
.platform {
  width: 1200px;
  min-height: 300px;
  background-color: #fff;
}
.message {
  width: 1200px;
  // background-color: skyblue;
  display: block;
  height: 100%;
  margin-bottom: 20px;
  margin-top: 56px;
}
.serverImg{
width: 1200px;
height: 627px;
}
.effectImg{
  width: 1200px;
  height: 906px;
}
.successImg{
width: 1200px;
height: 1349px;
}
</style>
