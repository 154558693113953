
import { defineComponent } from "vue";
import Swiper from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
import Box from "./modules/box.vue";
import Sixbox from "./modules/SixBoxa.vue";
import FourBoxa from "./modules/FourBoxa.vue";
import Message from "@/components/message/Message.vue";

export default defineComponent({
  name: "home",
  components: {
    Swiper,
    SubNav,
    Message,
    Box,
    Sixbox,
    FourBoxa,
  },
  data() {
    return {
      serverImg: require("@/assets/1new/生涯咨询/2.jpg"),
      effectImg: require("@/assets/1new/生涯咨询/3.jpg"),
      successImg: require("@/assets/1new/生涯咨询/4.jpg"),
    };
  },
});
